<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Today Statistics</strong>
    </div>
    <div class="row">
      <div class="col-xl col-md">
        <dashboard-card :label="'REQUESTS'" :value="todayStatistics.requests | formatNumber"
          :color="'rgba(75,192,192,1)'">
        </dashboard-card>
      </div>
      <div class="col-xl col-md">
        <dashboard-card :value="todayStatistics.impressions | formatNumber" :label="'IMPRESSIONS'"
          :color="'rgb(255, 99, 132)'"></dashboard-card>
      </div>
      <div class="col-xl col-md">
        <dashboard-card :value="todayStatistics.netImpressions | formatNumber" :label="'NET IMPRESSIONS'"
          :color="'rgb(255, 99, 132)'"></dashboard-card>
      </div>
      <div class="col-xl col-md">
        <dashboard-card
          :value="todayStatistics.spent !== 0 ? ('$ ' + Math.round((todayStatistics.spent + Number.EPSILON) * 100) / 100) : '0'"
          :label="'SPENT'" :color="'rgb(54, 162, 235)'"></dashboard-card>
      </div>
      <div class="col-xl col-md">
        <dashboard-card
          :value="todayStatistics.revenue !== 0 ? ('$ ' + Math.round((todayStatistics.revenue + Number.EPSILON) * 100) / 100) : '0'"
          :label="'REVENUE'" :color="'rgb(54, 162, 235)'"></dashboard-card>
      </div>
      <div class="col-xl col-md">
        <dashboard-card
          :value="todayStatistics.revenue !== 0 ? ('$ ' + Math.round((todayStatistics.profit + Number.EPSILON) * 100) / 100) : '0'"
          :label="'PROFIT'" :color="'rgb(54, 162, 235)'"></dashboard-card>
      </div>
    </div>
    <div class="cui__utils__heading">
      <strong>This Month Statistics</strong>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <div class="">
                <line-chart v-if="thisMonthStats.display" :thisMonthStats="thisMonthStats" :key="renderKey"
                  :height="computedHeight" id="test" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cui__utils__heading mb-3">
      <strong>LAST MONTH PUBLISHER SPENDING</strong>
    </div>
    <div class="">
      <cui-general-17 componentType="display" :publishersData="publisherData"></cui-general-17>
    </div>
    <div class="cui__utils__heading mb-3">
      <strong>LAST MONTH ADVERTISER EARNING</strong>
    </div>
    <div class="">
      <cui-general-17v1 :xml="false" :advertisersData="advertiserData"></cui-general-17v1>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CuiGeneral17 from '@/components/kit/widgets/General/17'
import CuiGeneral17v1 from '@/components/kit/widgets/General/17v1'
import DashboardCard from '@/components/custom/dashboard/card'
import { getDashboardStats } from '@/api/display/dashboard'
import LineChart from '@/components/custom/dashboard/chart/index-display.vue'

const campaignColumns = [
  {
    title: 'Campaign',
    dataIndex: 'Campaign',
    key: 'Campaign',
    align: 'center',

  },
  {
    title: 'Net Impressions',
    dataIndex: 'NetImpressions',
    key: 'NetImpressions',
    align: 'right',
    slots: { title: 'Net Impressions' },
    scopedSlots: { customRender: 'NetImpressions' },
  },
  {
    title: 'Net Clicks',
    dataIndex: 'NetClicks',
    key: 'NetClicks',
    align: 'right',
    slots: { title: 'NetClicks' },
    scopedSlots: { customRender: 'NetClicks' },
  },
  {
    title: 'Spent',
    dataIndex: 'Spent',
    key: 'Spent',
    align: 'right',
    slots: { title: 'Spent' },
    scopedSlots: { customRender: 'Spent' },
  },
  {
    title: 'Revenue',
    dataIndex: 'Revenue',
    key: 'Revenue',
    align: 'right',
    slots: { title: 'Revenue' },
    scopedSlots: { customRender: 'Revenue' },
  },
]

const searchfeedColumns = [
  {
    title: 'Feed',
    dataIndex: 'Feed',
    key: 'Feed',
    align: 'center',
  },
  {
    title: 'Requests',
    dataIndex: 'Requests',
    key: 'Requests',
    align: 'right',
    slots: { title: 'Requests' },
    scopedSlots: { customRender: 'Requests' },
  },
  {
    title: 'Ad Responses',
    dataIndex: 'AdResponses',
    key: 'AdResponses',
    align: 'right',
    slots: { title: 'AdResponses' },
    scopedSlots: { customRender: 'AdResponses' },
  },
  {
    title: 'Clicks',
    dataIndex: 'Clicks',
    key: 'Clicks',
    align: 'right',
    slots: { title: 'Clicks' },
    scopedSlots: { customRender: 'Clicks' },
  },
  {
    title: 'Net Clicks',
    dataIndex: 'NetClicks',
    key: 'NetClicks',
    align: 'right',
    slots: { title: 'NetClicks' },
    scopedSlots: { customRender: 'NetClicks' },
  },
  {
    title: 'Spent',
    dataIndex: 'Spent',
    key: 'Spent',
    align: 'right',
    slots: { title: 'Spent' },
    scopedSlots: { customRender: 'Spent' },
  },
]

export default {
  components: {
    LineChart,
    DashboardCard,
    CuiGeneral17,
    CuiGeneral17v1,
  },
  computed: {
    ...mapState(['settings']),
    computedHeight: function () {
      if (this.$store.state.settings.isMobileView === true) {
        return 300
      } else {
        return 90
      }
    },
  },
  watch: {
    computedHeight: function () {
      this.renderKey = this.renderKey + 1
    },
  },
  data() {
    return {
      renderKey: 0,
      i: 1,
      publisherData: [],
      advertiserData: [],
      thisMonthStats: {
        display: false,
      },
      campaignColumns,
      searchfeedColumns,
      todayStatistics: {
        requests: 0,
        impressions: 0,
        netImpressions: 0,
        revenue: 0,
        totalClicks: 0,
        netClicks: 0,
        spent: 0,
        profit: 0,
      },
    }
  },
  methods: {
    formatSpent(num) {
      return parseInt(num).toLocaleString()
    },
  },
  filters: {
    formatNumber(num) {
      // return Math.round((num + Number.EPSILON) * 100) / 100
      return parseInt(num).toLocaleString()
    },
  },
  beforeMount() {
    getDashboardStats().then(response => {
      if (response) {
        this.todayStatistics.requests = response.TodayStats.Requests
        this.todayStatistics.impressions = response.TodayStats.Impressions
        this.todayStatistics.netImpressions = response.TodayStats.NetImpressions
        this.todayStatistics.spent = Number(response.TodayStats.Spent.toFixed(2))
        this.todayStatistics.revenue = Number(response.TodayStats.Revenue.toFixed(2))
        this.todayStatistics.profit = Number(response.TodayStats.Profit.toFixed(2))
        this.thisMonthStats = { ...response.ThisMonthStats }
        this.publisherData = response.PublisherLastMonthData
        this.advertiserData = response.AdvertiserLastMonthData
        this.thisMonthStats.display = true
      }
    }).catch(error => console.log(error))
  },
}
</script>

<style>
.table-overflow-x {
  width: 100%;
  float: left;
  overflow: hidden;
  overflow-x: scroll;
}
.font-small {
  font-size: 60%;
}
/* .font-weight-bold {
  font-size: 150%;
} */
</style>
